<template>
  <div class="lists-wrapper__lists">
    <div class="grid-wrapper">
      <div class="col col-12">
        <div class="grid-info flex-center">
          <span class="grid-heading">
            <h1>{{ $t('Bulk invoicing') }}</h1>
            <span class="entries">{{ NumberFormat(entries) }} {{ $t(`entr${entries == 1 ? 'y' : 'ies'}`) }}</span>
          </span>
          <div class="grid-actions">
            <a class="button" style="min-width: 115px; margin: 22px 0 0 auto;" @click.prevent="CreateBulkListShow" href="">{{ $t('Create') }}</a>
          </div>
          <!--
          <div class="grid-search">
            <input type="text" @keypress.enter="Search" :value="search.query" :placeholder="$t('Search by list ID')">
          </div>
          -->
        </div>
        <table class="list table odd-even">
          <thead>
            <tr class="list__actions">
              <th class="bulk-id">{{ $t('Bulk ID') }}</th>
              <th class="company">{{ $t('Company') }}</th>
              <th class="orders">{{ $t('Orders') }}</th>
              <th class="created-at">{{ $t('Created') }}</th>
              <th class="created-by">{{ $t('Created by') }}</th>
              <th class="reference">{{ $t('Reference') }}</th>
              <th class="edit"></th>
            </tr>
          </thead>
          <tbody>
            <tr :data-id="item.id" class="list__row clickable" @mousedown="ClickOpen" @mousemove="ClickOpen" @mouseup="ClickOpen" :key="index" v-for="(item, index) in lists">
              <td class="invoice-id">{{ item.id }}</td>
              <td class="company">{{ item.company }}</td>
              <td class="orders">{{ NumberFormat(item.order_count) }}</td>
              <td class="created-at">{{ DateFormat(item.create_at) }}</td>
              <td class="created-by">{{ item.create_by }}</td>
              <td class="reference">{{ item.reference }}</td>
              <td @mouseenter="SetBackground" @mouseleave="SetBackground" class="actions">
                <a @click.prevent href="" class="icon dots">
                  <ul class="item-actions">
                    <li>
                      <a @click.prevent="ViewListShow(item.id)" href="">{{ $t('View details') }}</a>
                    </li>
                    <li>
                      <a @click.prevent="OpenPDF(item.id)" href="">{{ $t('Open PDF') }}</a>
                    </li>
                    <li>
                      <a @click.prevent="DownloadPDF(item)" href="">{{ $t('Download') }}</a>
                    </li>
                    <li>
                      <a @click.prevent="PrintPDF(item.id)" href="">{{ $t('Print') }}</a>
                    </li>
                  </ul>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="grid-pagination">
          <div class="page-navigation">
            <div class="page-turn prev disabled" @click="PageController(false)"></div>
            <div class="page-number">
              <label class="page-number-current">
                <input type="number" min="1" :max="page.last" :value="page.current" @blur="PageNavigator" @keydown="PageNavigator">
                <span class="placeholder">{{ NumberFormat(page.current) }}</span>
              </label>
              <span class="page-number-separator">/</span>
              <div class="page-number-last">{{ NumberFormat(page.last) }}</div>
            </div>
            <div class="page-turn next" :class="{disabled: page.last == 1}" @click="PageController(true)"></div>
          </div>
        </div>
      </div>
    </div>

    <Modal modal="create" :value="modal.create.open" :title="$t('New bulk invoicing')">
      <div id="create-list" class="create-list">
        <div class="search-form">
          <div class="form-elements">
            <div style="width: 100%; display: flex; align-items: flex-end;">
              <div class="label country" style="width: 300px; flex-shrink: 0;">
                <span class="label-text">
                  {{ $t(this.country.label) }}
                </span>
                <v-select name="country" @input="SelectCountry" :options="Alphabetize(country.options.map(o => {o.label = CountryName(o.code); return o;}), 'label')">
                  <template v-slot:selected-option="option">
                    <div class="truncate">
                      <Flag :code="option.code" size="small" />
                      <span>{{ option.label }}</span>
                    </div>
                  </template>
                  <template slot="option" slot-scope="option">
                    <div class="truncate">
                      <Flag :code="option.code" size="small" />
                      <span>{{ option.label }}</span>
                    </div>
                  </template>
                </v-select>
              </div>
              <div class="label courier" style="width: 300px; flex-shrink: 0; margin-left: 30px;">
                <span class="label-text">
                  {{ $t(this.courier.label) }}
                </span>
                <v-select name="courier" @input="SelectCourier" :options="courier.options">
                  <template v-slot:selected-option="option">
                    <span :class="['courier', option.code]">
                      {{ option.label }}
                    </span>
                  </template>
                  <template slot="option" slot-scope="option">
                    <span :class="['courier', option.code]">
                      {{ option.label }}
                      <span class="count" :class="{spinner: courier.orders[option.code].fetching}">
                        {{ NumberFormat(courier.orders[option.code].count) }}
                      </span>
                    </span>
                  </template>
                </v-select>
              </div>
              <div class="label courier" style="width: 400px; flex-shrink: 0; margin-left: 30px;">
                <span class="label-text">
                  {{ $t('Shipping method') }}
                </span>
                <v-select name="method" @input="SelectShippingMethod" :options="modal.create.shipping">
                  <template v-slot:selected-option="option">
                    <div class="truncate">
                      <span :class="['courier', option.agent]">
                        {{ option.label }}
                      </span>
                    </div>
                  </template>
                  <template slot="option" slot-scope="option">
                    <div class="truncate">
                      <span :class="['courier', option.agent]">
                        {{ option.label }}
                      </span>
                    </div>
                  </template>
                </v-select>
              </div>
              <div class="grid-state" style="max-width: 160px; min-width: 160px; padding: 4px 0 0; margin-left: 30px;">
                <p class="grid-heading">{{ $t('Page size') }}</p>
                <v-select name="page-size-select" @input="SelectCreatePageSize" v-model="modal.create.search.size" :options="modal.create.sizes" :searchable="false" :clearable="false" />
              </div>
            </div>
            <div style="width: 100%; display: flex; align-items: flex-end;">
              <div class="label date" style="width: 400px; flex-shrink: 0;">
                <span class="label-text">{{ $t('Date range') }}</span>
                <div class="grid-wrapper">
                  <div class="grid-info" style="width: 100%; margin: 0;">
                    <div class="grid-date" style="width: 100%;">
                      <div class="date-range flex-row">
                        <div class="input">
                          <VueCtkDateTimePicker id="date-range" v-model="date" @input="SelectDateRange" :range="true" label="" hint="" :locale="$i18n.locale.split('_')[0].replace(/no/, 'nb')" formatted="ddd, MMM D, YYYY" format="YYYY-MM-DD HH:mm:ss" :first-day-of-week="1" input-size="sm" :no-shortcuts="true" :no-button="true" :auto-close="true" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="search-result">
          <div class="list-wrapper">
            <div class="list-body">
              <div class="list-head">
                <ul class="list-label">
                  <li class="list-row">
                    <div class="list-col order-id" style="display: flex; flex-direction: row; align-items: center;">
                      <div class="label" style="display: table-cell; z-index: 3;">
                        <div class="v-wrapper checkbox">
                          <label class="v-checkbox-label">
                            <input class="v-checkbox" type="checkbox" @input.prevent="HandleDropdownTrigger" :disabled="!modal.create.items.length">
                            <div class="v-checkbox-toggle dropdown-trigger">
                              <div class="dropdown-list">
                                <div class="dropdown-item" :key="option" v-for="option of Object.keys(modal.create.select.option).filter(option => modal.create.select.option[option])" @click="HandleDropdownOption(option)">{{ $t(Capitalize(option.replace('_', ' '))) }}</div>
                              </div>
                            </div>
                          </label>
                        </div>
                      </div>
                      <span style="margin-left: 10px;">{{ $t('Order number') }}</span>
                    </div>
                    <div class="list-col company">{{ $t('Company') }}</div>
                    <div class="list-col webshop">{{ $t('Webshop') }}</div>
                    <div class="list-col order-date">{{ $t('Order date') }}</div>
                    <div class="list-col">{{ $t('Delivery company') }}</div>
                  </li>
                </ul>
              </div>
              <ul class="list-content odd-even">
                <li class="list-row" :key="item.id" v-for="item in modal.create.list.items">
                  <div class="list-col order-id" style="display: flex; flex-direction: row; align-items: center;">
                    <div class="label">
                      <div class="v-wrapper checkbox" style="min-height: 0px;">
                        <label class="v-checkbox-label" style="min-height: 0px;">
                          <input class="v-checkbox" type="checkbox" :id="item.id" :name="item.id" :checked="item.checked" v-model="item.checked" @change="ToggleCheckbox($event, item)">
                          <span class="v-checkbox-toggle" style="width: 18px; height: 18px;" />
                        </label>
                      </div>
                    </div>
                    <span style="margin-left: 10px;" v-html="Hyperlink({href: ParcelOrder(item.id), target: '_blank', text: item.company_id + ':' + item.increment_id})" />
                  </div>
                  <div class="list-col company">{{ item.company_name }}</div>
                  <div class="list-col webshop">
                    <div style="display: flex; align-items: center;">
                      <Flag :code="item.webshop.country" size="small" :title="LanguageName(item.webshop.language)" />
                      <span style="margin-left: 10px;" v-html="Hyperlink({href: item.webshop.url, target: '_blank', text: item.webshop.url.replace('https://', ''), title: LanguageName(item.webshop.language)})" />
                    </div>
                  </div>
                  <div class="list-col order-date">{{ DateFormat(item.order_date) }}</div>
                  <div class="list-col trackingnumber">
                    <div class="shipping">
                      <span :class="['courier-icon', item.agent_code]" :title="item.agent_name" />
                      <span class="shipping-method">{{ item.shipping_description }}</span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="footer-content">
          <div class="entries">{{ NumberFormat(modal.create.list.selected) }} / {{ NumberFormat(modal.create.list.entries) }}</div>
          <div style="margin: 0 60px;">
            <div class="page-navigation">
              <div class="page-turn prev disabled" @click="ModalPageController(false)" />
              <div class="page-number">
                <label class="page-number-current">
                  <input type="number" min="1" :max="modal.create.page.last" :value="modal.create.page.current" @blur="ModalPageNavigator" @keydown="ModalPageNavigator">
                  <span class="placeholder">{{NumberFormat(modal.create.page.current)}}</span>
                </label>
                <span class="page-number-separator">/</span>
                <div class="page-number-last">{{NumberFormat(modal.create.page.last)}}</div>
              </div>
              <div class="page-turn next" :class="{disabled: modal.create.page.last == 1}" @click="ModalPageController(true)" />
            </div>
          </div>
          <div class="label timezone">
            <span class="label-text">
              {{ $t(this.timezone.label) }}
            </span>
            <v-select name="timezone" class="drop-up" v-model="timezone.selected" :options="timezone.options" append-to-body :calculate-position="withPopper" :clearable="false" :required="true">
              <!--
              <template #search="{attributes, events}">
                <input class="vs__search" v-bind="attributes" v-on="events" :required="!timezone.selected">
              </template>
              -->
              <template v-slot:selected-option="option">
                <div class="truncate" :title="option.label">
                  <!-- <Flag :code="option.code" size="small" /> -->
                  <span>{{ option.label }}</span>
                </div>
              </template>
              <template slot="option" slot-scope="option">
                <div class="truncate" :title="option.label">
                  <!-- <Flag :code="option.code" size="small" /> -->
                  <span>{{ option.label }}</span>
                </div>
              </template>
            </v-select>
          </div>
          <button class="button submit done" @click="CreateBulkListItem">{{ $t('Create') }}</button>
        </div>
      </div>
    </Modal>

    <Modal modal="view" :value="modal.view.open" :title="$t('Bulk invoicing')">
      <div id="view-list" class="view-list">
        <div class="information">
          <span class="info-primary">
            <p class="info-row">{{ $t('Bulk ID') }}: <span>{{ modal.view.item.id }}</span></p>
            <p class="info-row">{{ $t('Created') }}: <span>{{ DateFormat(modal.view.item.create_at) }}</span></p>
            <p class="info-row">{{ $t('Created by') }}: <span>{{ modal.view.item.create_by }}</span></p>
          </span>
          <span class="info-secondary">
            <p class="info-row">{{ $t('Orders') }}: <span>{{ modal.view.item.orders && NumberFormat(modal.view.item.orders.length) }}</span></p>
            <p class="info-row">{{ $t('Company') }}: <span>{{ modal.view.item.company }}</span></p>
            <p class="info-row">{{ $t('Reference') }}: <span>{{ modal.view.item.reference }}</span></p>
          </span>
        </div>
        <div class="list-view">
          <div class="list-wrapper">
            <div class="list-body">
              <div class="list-head">
                <ul class="list-label">
                  <li class="list-row">
                    <div class="list-col">{{ $t('Order number') }}</div>
                    <div class="list-col">{{ $t('Company') }}</div>
                    <div class="list-col">{{ $t('Webshop') }}</div>
                    <div class="list-col">{{ $t('Shipping method') }}</div>
                  </li>
                </ul>
              </div>
              <ul class="list-content odd-even">
                <li class="list-row" v-bind:key="order.id" v-for="order in modal.view.item.orders">
                  <!-- <div class="list-col">{{ order.company_id }}:{{ order.increment_id }}</div> -->
                  <div class="list-col" v-html="Hyperlink({
                    href: ParcelOrder(order.id), target: '_blank', text: order.company_id + ':' + order.increment_id
                  })" />
                  <div class="list-col">{{ GetCompanyById(order.company_id).name }}</div>
                  <div class="list-col">
                    <div style="display: flex; align-items: center;">
                      <Flag :code="order.webshop.country" size="small" :title="LanguageName(order.webshop.language)" />
                      <span style="margin-left: 10px;" v-html="Hyperlink({href: order.webshop.url, target: '_blank', text: order.webshop.url.replace('https://', ''), title: LanguageName(order.webshop.language)})" />
                    </div>
                  </div>
                  <div class="list-col trackingnumber">
                    <div class="shipping">
                      <span :class="['courier-icon', order.agent_code]" :title="(courier.options.find(option => option.code == order.agent_code) || {}).label" />
                      <span class="shipping-method">{{ order.shipping_description }}</span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="button-container actions">
            <button class="button green" @click.prevent="OpenPDF(modal.view.item.id)">{{ $t('Open PDF') }}</button>
            <button class="button green" @click.prevent="DownloadPDF(modal.view.item)">{{ $t('Download') }}</button>
            <button class="button blue" @click.prevent="PrintPDF(modal.view.item.id)">{{ $t('Print') }}</button>
          </div>
        </div>
      </div>
    </Modal>

    <div class="loading" v-if="loading">
      <div class="loading-element">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>

  </div>
</template>

<script>
  import { FormElementsBehaviour }  from '@/mixins/FormElementsBehaviour';
  import { TableElementsBehaviour } from '@/mixins/TableElementsBehaviour';
  import { BPA }                    from '@/helpers/BPA';
  import { Tool }                   from '@/helpers/Tool';
  import { createPopper }           from '@popperjs/core';
  import Modal                      from '@/components/snippets/Modal';
  import Flag                       from '@/components/snippets/Flag';

  export default {
    name: 'ListsInvoices',
    mixins: [BPA, FormElementsBehaviour, TableElementsBehaviour],
    components: {
      Modal,
      Flag
    },
    data() {
      return {
        loading: false,
        entries: 0,
        clipboard: null,
        clickables: [],
        cached: {},
        lists: [],
        list: {},
        page: {
          current: 1,
          number: 1,
          last: 1,
          size: 20,
          order: {
            direction: 'desc',
            by: ''
          }
        },
        search: {
          query: ''
        },
        date: {
          start: '',
          end: '',
          utc: {
            start: '',
            end: ''
          }
        },
        country: {
          label: 'Country',
          selected: '',
          options: []
        },
        courier: {
          label: 'Delivery company',
          selected: '',
          options: [],
          orders: {}
        },
        timezone: {
          label: 'Time zone',
          selected: '',
          options: []
        },
        shipping: {},
        modal: {
          create: {
            open: false,
            search: {
              size: 20,
              offset: 1,
              shipping_description: ''
            },
            page: {
              current: 1,
              number: 1,
              last: 1
            },
            sizes: [10, 20, 30, 50, 100],
            shipping: [],
            checked: [],
            items: [],
            pages: [],
            list: {
              items: [],
              entries: 0,
              selected: 0
            },
            select: {
              options: [
                {code: 'select_all', label: 'Select all'},
                {code: 'deselect_all', label: 'Deselect all'},
                {code: 'select_page', label: 'Select page'},
                {code: 'deselect_page', label: 'Deselect page'}
              ],
              option: {
                select_all: true,
                deselect_all: false,
                select_page: true,
                deselect_page: false
              }
            }
          },
          view: {
            open: false,
            item: {}
          }
        }
      }
    },
    created() {},
    async mounted() {
      this.clipboard = await Tool.PasteFromClipboard(true);

      let couriers = BPA.api.Couriers('GET');
      let countries = BPA.api.Countries('GET');

      this.$eventHub.$on('CloseModal', (modal_name) => {
        let modal = this.modal;
        if (modal[modal_name]) {
          modal[modal_name].open = false;
        }
        if (modal_name == 'create') {
          this.ClearCreateListOrders();
          this.country.selected = '';
          this.courier.selected = '';
          this.date = {
            start: '', 
            end: '',
            utc: {
              start: '',
              end: ''
            }
          };
        }
        if (modal_name == 'view') {
          this.list = {};
          this.cached.list = {};
          BPA.cache.session({name: this.$options.name, set: {list: {}}});
          for (let i = 0; i < this.clickables.length; i++) {
            if (this.clickables[i].classList.contains('selected')) {
              this.clickables[i].classList.remove('selected');
            }
          }
        }
      });

      for (let code in couriers) if (code != 'shop') {
        this.courier.options.push({code: code, label: couriers[code]});
        this.courier.orders[code] = {fetching: false, count: 0};
      }

      for (let country of countries) {
        this.country.options.push({code: country.iso_code_2.toLowerCase(), label: country.name});
      }

      this.timezone.options = window.Intl.supportedValuesOf('timeZone').map(option => ({label: String(option).replace(/_/g, ' '), value: option || ''}));
      /*
      let locales = {
        da_DK: [],
        en_GB: [],
        no_NO: [],
        sv_SE: []
      };
      let timezones = this.timezone.options.map((option, i) => {
        return {[option.value]: Object.keys(locales).reduce((accumulator, locale) => {
          return {...accumulator, [locale]: locale == 'en_GB' ? option.label : locales[locale][i]};
        }, {})};
      });
      console.log(this.CloneObject(timezones))
      */
      this.courier.counter = document.createElement('div');
      this.courier.counter.classList.add('counter');

      if (this.clipboard) {
        let invoice_id = this.clipboard.replace(/invoices\//, '');
        if (invoice_id) this.ViewListShow(invoice_id);
      } else {
        this.cached = BPA.cache.local({name: this.$options.name, get: ['page', 'search']});
        for (let key of Object.keys(this.cached)) this[key] = {...this[key], ...this.cached[key]};
        this.cached = {...this.cached, ...BPA.cache.session({name: this.$options.name, get: 'list'})};
      }

      this.QueryLists();
    },
    methods: {
      withPopper(dropdownList, component, {width}) {
        dropdownList.style.width = width;
        let popper = createPopper(component.$refs.toggle, dropdownList, {
          placement: 'top',
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -1],
              }
            },
            {
              name: 'toggleClass',
              phase: 'write',
              enabled: true,
              fn({state}) {
                component.$el.classList.toggle(
                  'drop-up',
                  state.placement === 'top'
                )
              }
            }
          ]
        });
        return () => popper.destroy()
      },
      Hyperlink(props = {}) {
        return Tool.Hyperlink(props);
      },
      DateToISO(date, local) {
        return Tool.DateToISO(date, local);
      },
      DateToUTC(date) {
        return Tool.DateToUTC(date);
      },
      DateFormat(date) {
        return Tool.DateFormat(date);
      },
      NumberFormat(number) {
        return Tool.NumberFormat(number);
      },
      CountryName(country_code, locale) {
        return Tool.CountryName(country_code, locale);
      },
      LanguageName(country_code) {
        return Tool.LanguageName(country_code);
      },
      Alphabetize(list, prop) {
        return Tool.Alphabetize(list, prop);
      },
      Capitalize(string) {
        return String(Tool.Capitalize(string));
      },
      Webshop(company_id, increment_id) {
        return Tool.Webshop(company_id, increment_id);
      },
      CloneObject(object = {}) {
        return Tool.CloneObject(object);
      },
      Empty(element) {
        while (element.firstChild) {
          element.removeChild(element.firstChild);
        }
      },
      ParcelOrder(order_id = '') {
        return window.location.origin + '/parcels/orders?id=' + order_id;
      },
      Search(event) {
        let input = event.target;
        let value = input.value.replace(/\s+/g, ' ').trim();
        this.search.query = value;
        this.page.current = 1;
        input.value = value;
        this.QueryLists();
        input.blur();
      },
      GetCompanyById(company_id) {
        return BPA.api.Companies('GET').find(company => company.id == company_id);
      },
      PageController(next_page) {
        let load_page;
        const page = {};
        document.querySelectorAll('.page-turn').forEach(arrow => {
          if (arrow) page[arrow.classList.contains('prev') ? 'prev' : 'next'] = arrow;
        });
        if (next_page) {
          if (this.page.current != this.page.last) {
            this.page.current++;
            load_page = true;
            if (this.page.current == this.page.last) {
              page.next.classList.add('disabled');
            }
            page.prev.classList.remove('disabled');
          }
        } else {
          if (this.page.current > 1) {
            this.page.current--;
            load_page = true;
            if (this.page.current == 1) {
              page.prev.classList.add('disabled');
            }
            page.next.classList.remove('disabled');
          }
        }
        if (load_page) {
          this.QueryLists();
        }
      },
      PageNavigator(e) {
        const page = {
          event: e.type,
          input: e.target,
          last: Number(e.target.max),
          first: Number(e.target.min),
          number: Number(e.target.value)
        }
        const within_limits = (n) => {
          return n >= page.first && n <= page.last;
        }
        const set_page_number = (n) => {
          this.page.number = n || page.number;
          page.number = Number(this.page.number);
        }
        document.querySelectorAll('.page-turn').forEach(arrow => {
          if (arrow) page[arrow.classList.contains('prev') ? 'prev' : 'next'] = arrow;
        });
        set_page_number();
        if (page.event == 'keydown') {
          if (e.key == 'Enter') {
            page.input.blur();
          }
        }
        if (page.event == 'blur') {
          ['prev', 'next'].map(arrow => {
            page[arrow] && page[arrow].classList.remove('disabled');
          });
          if (page.number <= page.first) {
            set_page_number(page.first);
            page.input.value = page.first;
            page.prev.classList.add('disabled');
            if (page.last == page.first) {
              page.next.classList.add('disabled');
            }
          } else if (page.number >= page.last) {
            set_page_number(page.last);
            page.input.value = page.last;
            page.next.classList.add('disabled');
            if (page.first == page.last) {
              page.prev.classList.add('disabled');
            }
          }
          if (within_limits(page.number) && page.number != this.page.current) {
            this.page.current = page.number;
            this.QueryLists();
          }
        }
      },
      SetPageJumpWidth() {
        const current = document.querySelector('.page-number-current');
        const last = document.querySelector('.page-number-last');
        if (current && last) {
          const rect = last.getBoundingClientRect();
          const input = current.querySelector('input');
          if (rect && rect.width) {
            current.style.setProperty('width', rect.width + 'px');
          } else {
            current.style.removeProperty('width');
          }
          if (input) {
            input.dispatchEvent(new Event('blur'));
          }
        }
      },
      ModalPageController(next_page) {
        let load_page;
        const page = {};
        const modal_page = this.modal.create.page;
        document.querySelectorAll('.modal .page-turn').forEach(arrow => {
          if (arrow) page[arrow.classList.contains('prev') ? 'prev' : 'next'] = arrow;
        });
        if (next_page) {
          if (modal_page.current != modal_page.last) {
            modal_page.current++;
            load_page = true;
            if (modal_page.current == modal_page.last) {
              page.next.classList.add('disabled');
            }
            page.prev.classList.remove('disabled');
          }
        } else {
          if (modal_page.current > 1) {
            modal_page.current--;
            load_page = true;
            if (modal_page.current == 1) {
              page.prev.classList.add('disabled');
            }
            page.next.classList.remove('disabled');
          }
        }
        if (load_page) {
          this.SetModalPage();
        }
      },
      ModalPageNavigator(e) {
        const page = {
          event: e.type,
          input: e.target,
          last: Number(e.target.max),
          first: Number(e.target.min),
          number: Number(e.target.value)
        }
        const create = this.modal.create;
        const modal_page = create.page;
        const within_limits = (n) => {
          return n >= page.first && n <= page.last;
        }
        const set_page_number = (n) => {
          modal_page.number = n || page.number;
          page.number = Number(modal_page.number);
        }
        document.querySelectorAll('.modal .page-turn').forEach(arrow => {
          if (arrow) page[arrow.classList.contains('prev') ? 'prev' : 'next'] = arrow;
        });
        set_page_number();
        if (page.event == 'keydown') {
          if (e.key == 'Enter') {
            page.input.blur();
          }
        }
        if (page.event == 'blur') {
          ['prev', 'next'].map(arrow => {
            page[arrow] && page[arrow].classList.remove('disabled');
          });
          if (page.number <= page.first) {
            set_page_number(page.first);
            page.input.value = page.first;
            page.prev.classList.add('disabled');
            if (page.last == page.first) {
              page.next.classList.add('disabled');
            }
          } else if (page.number >= page.last) {
            set_page_number(page.last);
            page.input.value = page.last;
            page.next.classList.add('disabled');
            if (page.first == page.last) {
              page.prev.classList.add('disabled');
            }
          }
          if (within_limits(page.number) && page.number != modal_page.current) {
            modal_page.current = page.number;
            this.SetModalPage();
          }
        }
      },
      ModalSetPageJumpWidth() {
        const current = document.querySelector('.modal .page-number-current');
        const last = document.querySelector('.modal .page-number-last');
        if (current && last) {
          const rect = last.getBoundingClientRect();
          const input = current.querySelector('input');
          if (rect && rect.width) {
            current.style.setProperty('width', rect.width + 'px');
          } else {
            current.style.removeProperty('width');
          }
          if (input) {
            input.dispatchEvent(new Event('blur'));
          }
        }
      },
      SelectedItems() {
        let items = this.modal.create.select.items;
        let all = items.all.length - items.unchecked.length;
        let checked = items.checked.length;
        return (all < 0 ? 0 : all) || (checked < 0 ? 0 : checked);
      },
      HandleDropdownTrigger(event) {
        let trigger = event.target;
        if (trigger.checked) {
          let mousedown = (e) => {
            if (!trigger.parentElement.contains(e.target)) {
              window.removeEventListener('mousedown', mousedown);
              trigger.checked = false;
            }
          }
          window.addEventListener('mousedown', mousedown, false);
        }
      },
      HandleDropdownOption(option) {
        let create = this.modal.create;
        let items = create.items;
        let list = create.list;
        switch (option) {
          case 'select_all': {
            for (let i = 0; i < items.length; i++) {
              items[i].checked = true;
            }
            break;
          }
          case 'deselect_all': {
            for (let i = 0; i < items.length; i++) {
              items[i].checked = false;
            }
            break;
          }
          case 'select_page': {
            for (let i = 0; i < list.items.length; i++) {
              list.items[i].checked = true;
            }
            break;
          }
          case 'deselect_page': {
            for (let i = 0; i < list.items.length; i++) {
              list.items[i].checked = false;
            }
          }
        }
        this.SetModalSelection();
      },
      ToggleCheckbox(event, item) {
        let checkbox = event.target;
        let checked = checkbox.checked;
        item.checked = checked;
        this.SetModalSelection();
      },
      OrderListBy(property) {
        if (this.page.order.by == property) {
          if (this.page.order.direction == 'desc') {
            this.page.order.direction = 'asc';
          } else {
            this.page.order.direction = 'desc';
          }
        }
        this.page.order.by = property;
        this.QueryLists();
      },
      QueryLists() {
        this.loading = true;
        BPA.api.GetInvoicingAbroadList({
          query: this.search.query,
          order_direction: this.page.order.direction,
          page_offset: this.page.current,
          order_by: this.page.order.by,
          //page_size: this.page.size
        }).then(response => {
          return BPA.api.response({response, return: 'json'});
        }).then(response => {
          this.loading = false;
          if (!response.ok || !response.result) return;
          let list = response.result || {};
          if (!list.page_offset) list.page_offset = 0;
          this.page.current = list.page_offset + 1;
          this.page.last = list.total_pages || 1;
          this.entries = list.items_total;
          this.lists = list.items;
          if (!this.clipboard) {
            BPA.cache.local({name: this.$options.name, set: {page: this.page, search: this.search}});
          }
          this.$nextTick().then(() => {
            this.clickables = document.querySelectorAll('.clickable');
            if (this.cached.list && Object.keys(this.cached.list).length) {
              this.ViewListShow(this.cached.list.id);
            }
            this.SetPageJumpWidth();
          });
        }).catch(e => e);
      },
      async GetShippingMethods(company_code = BPA.util.GetCompany()) {
        if (Object.keys(this.shipping).length) return this.shipping;
        return await BPA.api.GetShippingOptions(company_code).then(response => {
          return BPA.api.response({response, return: 'json'});
        }).then(response => {
          if (!response.ok) {
            this.shipping = {};
            return [];
          }
          let shipping = {};
          let options = response.result || [];
          for (let option of options) {
            shipping[option.shipping_method] = option.agent_code;
          }
          this.shipping = shipping;
          return options;
        }).catch(e => e);
      },
      async SelectCountry(e) {
        let country = this.country;
        let courier = this.courier;
        let orders = courier.orders;
        let counter = courier.counter;
        let courier_option_count = 0;
        let courier_order_count_total = 0;
        const courier_options_total = Object.keys(orders).length;
        const courier_select = document.querySelector('div[name=courier]');
        const courier_input = courier_select.querySelector('input.vs__search');
        const courier_list = courier_select.querySelector('ul[role=listbox]');
        const focus = new Event('focus');
        const blur = new Event('blur');
        const updateOrderCount = () => {
          if (courier_list.children.length) {
            courier_input.dispatchEvent(blur);
            courier_input.dispatchEvent(focus);
          }
        }
        country.selected = e && e.code;
        counter.textContent = 0;
        counter.classList.add('spinner');
        if (country.selected && courier.selected) {
          this.loading = true;
          await this.GetInvoicingAbroadOrders({
            country: country.selected,
            courier: courier.selected
          });
          this.loading = false;
        }
        /*
        for (let agent in orders) {
          if (country.selected) {
            orders[agent].fetching = true;
            await BPA.api.GetInvoicingAbroadCount({
              country: country.selected,
              courier: agent              
            }).then(response => {
              return BPA.api.response({response, return: 'text'});
            }).then(response => {
              orders[agent].fetching = false;
              if (!response.ok) return;
              let count = response.result || 0;
              courier_option_count++;
              orders[agent].count = count;
              if (courier_option_count == courier_options_total) {
                counter.classList.remove('spinner');
                if (courier.selected) {
                  let count = orders[courier.selected].count;
                  counter.textContent = this.NumberFormat(count);
                }
                setTimeout(updateOrderCount);
              }
            }).catch(e => e);
          } else {
            counter.classList.remove('spinner');
            orders[agent].fetching = false;
            orders[agent].count = 0;
            setTimeout(updateOrderCount);
          }
        }
        */
        if (country.selected) {
          for (let agent in orders) {
            orders[agent].fetching = true;
          }
          let order_count = await BPA.api.GetInvoicingAbroadCount(country.selected).then(response => {
            return BPA.api.response({response, return: 'json'});
          }).then(response => {
            if (!response.ok) return;
            return response.result || {};
          }).catch(e => e);
          for (let agent in orders) {
            let count = order_count[agent] || 0;
            courier_option_count++;
            orders[agent].count = count;
            courier_order_count_total += count;
            if (courier_option_count == courier_options_total) {
              counter.classList.remove('spinner');
              if (courier.selected) {
                counter.textContent = this.NumberFormat(orders[courier.selected].count);
              } else {
                counter.textContent = this.NumberFormat(courier_order_count_total);
              }
              setTimeout(updateOrderCount);
            }
            orders[agent].fetching = false;
          }
        } else {
          counter.classList.remove('spinner');
          counter.textContent = 0;
          this.ClearCreateListOrders();
          for (let agent in orders) {
            orders[agent].fetching = false;
            orders[agent].count = 0;
            setTimeout(updateOrderCount);
          }
        }
      },
      async SelectCourier(e) {
        let country = this.country;
        let courier = this.courier;
        let counter = courier.counter;
        let create = this.modal.create;
        courier.selected = e && e.code;
        if (courier.selected) {
          await this.CreateShippingMethods();
          create.shipping = create.shipping.filter(option => {
            return option.agent == courier.selected;
          });
          let count = courier.orders[courier.selected].count;
          counter.textContent = this.NumberFormat(count);
          if (country.selected) {
            this.loading = true;
            await this.GetInvoicingAbroadOrders({
              country: country.selected,
              courier: courier.selected
            });
            this.loading = false;
          }
        } else {
          await this.CreateShippingMethods();
          this.ClearCreateListOrders();
          counter.textContent = 0;
        }
      },
      SelectShippingMethod(option) {
        let create = this.modal.create;
        let search = create.search;
        search.shipping_description = option ? option.code : '';
        this.ModalQueryOrders();
      },
      SelectDateRange(date) {
        if (!date) {
          this.date = {
            start: '', 
            end: '',
            utc: {
              start: '',
              end: ''
            }
          };
          return this.CheckOrdersInDateRange(true);
        }
        if (!date.start || !date.end) {
          return this.CheckOrdersInDateRange(true);
        }
        this.date = {
          start: date.start,
          end: date.end,
          utc: {
            start: this.DateToUTC(date.start),
            end: this.DateToUTC(date.end)
          }
        }
        //console.log(this.CloneObject(this.date))
        this.CheckOrdersInDateRange();
      },
      SelectCreatePageSize(option) {
        let create = this.modal.create;
        create.search.size = option;
        this.ModalQueryOrders();
      },
      CheckOrdersInDateRange(uncheck) {
        let create = this.modal.create;
        let list = create.list;
        let items = create.items;
        let orders = list.items;
        let date = this.date;
        let selected = 0;
        let date_only = (date) => this.DateToISO(date, true).split(' ')[0] + ' 00:00:00';
        for (let i = 0; i < items.length; i++) {
          let item = items[i];
          if (uncheck) {
            item.checked = false;
          } else {
            let order_date = date_only(item.order_date);
            let start_date = date_only(date.utc.start);
            let end_date = date_only(date.utc.end);
            let checked = order_date == start_date || order_date == end_date || (order_date >= start_date && order_date < end_date);
            if (checked) selected++;
            item.checked = checked;
          }
        }
        list.all = orders.length && orders.filter(item => item.checked).length == orders.length;
        list.selected = selected;
        this.$nextTick().then(() => {
          let first_checkbox_checked = document.querySelector('.v-checkbox:checked');
          if (first_checkbox_checked) {
            let list_body = document.querySelector('.search-result .list-body');
            let list_row = first_checkbox_checked.closest('.list-row');
            list_body.scrollTop = list_row.offsetTop;
          }
        });
      },
      async CreateBulkListShow() {
        let orders = this.courier.orders;
        for (let courier in orders) {
          orders[courier].fetching = false;
          orders[courier].count = 0;
        }
        this.courier.counter.textContent = 0;
        this.timezone.selected = this.timezone.options.find(option => {
          return option.value == window.Intl.DateTimeFormat().resolvedOptions().timeZone;
        }) || '';
        let create = this.modal.create;
        await this.CreateShippingMethods();
        this.ClearCreateListOrders();
        create.search.size = 20;
        create.open = true;
        this.$nextTick().then(() => {
          this.ModalSetPageJumpWidth();
          let el = document.querySelector('[name=courier] .vs__selected-options');
          el.parentElement.insertBefore(this.courier.counter, el.nextElementSibling);
        });
      },
      async CreateShippingMethods() {
        let create = this.modal.create;
        create.shipping = [];
        await this.GetShippingMethods();
        for (let method in this.shipping) {
          create.shipping.push({
            agent: this.shipping[method],
            code: method,
            label: method
          });
        }
      },
      CreateBulkListItem() {
        let country = this.country;
        let courier = this.courier;
        let orders = courier.orders;
        let create = this.modal.create;
        let params = {
          country: country.selected,
          courier: courier.selected
        };
        for (let prop in params) {
          if (!params[prop]) {
            return this.$eventHub.$emit('ShowMessages', {
              message: `${this[prop].label} is missing`,
              type: 'error',
              hide: 2000
            });
          }
        }
        let country_option = country.options.find(option => option.code == params.country);
        let country_name = this.CountryName(country_option.code, 'en');
        let courier_name = courier.options.find(option => option.code == params.courier).label;
        let order_count = orders[params.courier].count;
        if (order_count == 0) {
          return this.$eventHub.$emit('ShowMessages', {
            message: `There are no ${courier_name} orders to ${country_name}`,
            type: 'error',
            hide: 2000
          });
        }
        create.checked = create.items.map(item => item.checked && item.id).filter(e => e);
        console.log(create.checked)
        if (!create.checked.length) {
          return this.$eventHub.$emit('ShowMessages', {
            message: `There are no ${courier_name} orders selected`,
            type: 'error',
            hide: 2000
          });
        }
        params.body = {tz: this.timezone.selected.value, order_ids: create.checked};
        let message = `Creates bulk invoicing for deliveries to ${country_name} with ${courier_name}.`;
        if (create.checked.length > 1000) {
          message += `\n\n<b>Due to the number of orders (${this.NumberFormat(create.checked.length)}) the process may take a while.<b>`;
        }
        this.$eventHub.$emit('ValidateModalStart', {
          approve: 'Yes, create it',
          disapprove: 'No',
          message: message,
          type: 'success'
        });
        this.$eventHub.$on('ValidateModalStop', (approve) => {
          this.$eventHub.$off('ValidateModalStop');
          if (!approve) return;
          this.loading = true;
          BPA.api.CreateInvoicingAbroadItem(params).then(response => {
            return BPA.api.response({response, return: 'json'});
          }).then(response => {
            this.loading = false;
            if (!response.ok || !response.result) return;
            let json = response.result || {};
            this.$eventHub.$emit('ShowMessages', {
              message: json.reference + ' successfully created', 
              type: 'success', 
              hide: 2000
            });
            this.QueryLists();
            this.$eventHub.$emit('CloseModal', 'create');
          }).catch(e => e);
        });
      },
      ToggleAllOrders(event) {
        let checkbox = event.target;
        let checked = checkbox.checked;
        let create = this.modal.create;
        let list = create.list;
        let orders = list.items;
        for (let i = 0; i < orders.length; i++) {
          orders[i].checked = !!checked;
        }
        list.selected = orders.filter(order => order.checked).length;
        list.all = list.selected == orders.length;
      },
      ToggleOrder(event) {
        let checkbox = event.target;
        let checked = checkbox.checked;
        let create = this.modal.create;
        let list = create.list;
        let orders = list.items;
        for (let i = 0; i < orders.length; i++) {
          if (orders[i].id == checkbox.id) {
            orders[i].checked = !!checked;
            break;
          }
        }
        list.selected = orders.filter(order => order.checked).length;
        list.all = list.selected == orders.length;
      },
      ViewListShow(invoice_id) {
        for (let i = 0; i < this.clickables.length; i++) {
          if (this.clickables[i].dataset.id == invoice_id) {
            this.clickables[i].classList.add('selected');
            if (this.cached.list && Object.keys(this.cached.list).length) {
              this.clickables[i].scrollIntoView({
                behavior: 'auto',
                block: 'center',
                inline: 'center'
              });
            }
            break;
          }
        }
        this.loading = true;
        BPA.api.GetInvoicingAbroadItem(invoice_id).then(response => {
          return BPA.api.response({response, return: 'json'});
        }).then(async response => {
          this.loading = false;
          if (!response.ok || !response.result) return;
          let list = response.result || {};
          await this.GetShippingMethods();
          for (let order of list.orders) {
            order.agent_code = this.shipping[order.shipping_description];
            order.webshop = this.Webshop(order.company_id, order.increment_id);
          }
          BPA.cache.session({name: this.$options.name, set: {list}});
          this.modal.view.item = list;
          this.modal.view.open = true;
        }).catch(e => e);
      },
      PrintPDF(invoice_id) {
        let printer_id = (BPA.printer.fetch('a4') || {}).id;
        if (!printer_id) {
          this.$eventHub.$emit('ShowMessages', {
            message: 'A4 printer is not selected',
            type: 'error',
            hide: 2000
          });
          return;
        }
        this.$eventHub.$emit('ValidateModalStart', {
          approve: 'Yes, print it',
          disapprove: 'No',
          message: 'Prints current invoice.',
          type: 'success'
        });
        this.$eventHub.$on('ValidateModalStop', (approve) => {
          this.$eventHub.$off('ValidateModalStop');
          if (!approve) return;
          this.loading = true;
          BPA.api.PrintInvoicingAbroadItem({
            invoice_id: invoice_id,
            printer_id: printer_id
          }).then(response => {
            return BPA.api.response({response});
          }).then(response => {
            this.loading = false;
            if (!response.ok) return;
            this.$eventHub.$emit('ShowMessages', {
              message: 'Invoice sent to A4 printer',
              type: 'success',
              hide: 2000
            });
          }).catch(e => e);
        });
      },
      async OpenPDF(invoice_id) {
        let blob = await this.GetPdfAsBlob(invoice_id);
        let blobURL = URL.createObjectURL(blob);
        window.open(blobURL);
        URL.revokeObjectURL(blobURL);
      },
      async DownloadPDF(invoice) {
        let blob = await this.GetPdfAsBlob(invoice.id);
        if (blob) BPA.api.download({
          blob: blob, 
          name: `Bulk invoicing #${invoice.id} - ${invoice.reference}`, 
          new_tab: BPA.browser == 'firefox'
        });
      },
      async GetPdfAsBlob(invoice_id) {
        return await new Promise((resolve, reject) => {
          if (!invoice_id) return reject();
          this.loading = true;
          BPA.api.DownloadInvoicingAbroadItem(invoice_id).then(response => {
            return BPA.api.response({response, 
              return: () => {
                return response.arrayBuffer();
              }
            });
          }).then(response => {
            this.loading = false;
            if (!response.ok || !response.result) return reject();
            let arrayBuffer = response.result || {};
            resolve(new Blob([arrayBuffer], {type: 'application/pdf'}));
          }).catch(reject);
        }).catch(e => e);
      },
      async GetInvoicingAbroadOrders(params) {
        return await new Promise((resolve, reject) => {
          BPA.api.GetInvoicingAbroadOrders(params).then(response => {
            return BPA.api.response({response, return: 'json'});
          }).then(response => {
            this.ClearCreateListOrders();
            if (!response.ok) return reject();
            let items = response.result || [];
            let create = this.modal.create;
            create.list.selected = items.length;
            create.list.entries = items.length;
            items.sort((a, b) => new Date(a.order_date) - new Date(b.order_date));
            let courier_option = this.courier.options.find(option => option.code == params.courier);
            for (let i = 0; i < items.length; i++) {
              let item = items[i];
              item.company_name = BPA.company('name', item.company_id);
              item.agent_code = courier_option.code;
              item.agent_name = courier_option.label;
              item.webshop = this.Webshop(item.company_id, item.increment_id);
            }
            create.items = items;
            this.ModalQueryOrders();
            this.CheckOrdersInDateRange();
            this.$nextTick().then(() => {
              create.loading = false;
            });
            resolve(true);
          }).catch(reject);
        }).catch(e => e);
      },
      ModalQueryOrders() {
        let create = this.modal.create;
        let search = create.search;
        let items = create.items;
        let page = create.page;
        create.pages = [];
        items = items.filter(item => {
          if (search.shipping_description) {
            return item.shipping_description == search.shipping_description;
          } else return item;
        });
        for (let i = 0; i < items.length; i += search.size) {
          let order_page = items.slice(i, i + search.size);
          if (order_page.length) create.pages.push(order_page);
        }
        page.last = create.pages.length || 1;
        page.current = 1;
        this.SetModalPage();
        this.$nextTick().then(() => {
          this.ModalSetPageJumpWidth();
        });
      },
      SetModalSelection() {
        let create = this.modal.create;
        let list = create.list;
        let items = create.items;
        let select = create.select;
        const all_selected = (items = []) => !!(items.length && items.filter(item => item.checked).length == items.length);
        const some_selected = (items = []) => items.some(item => item.checked);
        for (let key of Object.keys(select.option)) {
          select.option[key] = false;
        }
        if (!all_selected(items)) {
          select.option.select_all = true;
          if (some_selected(items)) {
            select.option.deselect_all = true;
          }
        } else {
          select.option.deselect_all = true;
        }
        if (!all_selected(list.items)) {
          select.option.select_page = true;
          if (some_selected(list.items)) {
            select.option.deselect_page = true;
          }
        } else {
          select.option.deselect_page = true;
        }
        list.selected = items.filter(item => item.checked).length;
      },
      SetModalPage() {
        let create = this.modal.create;
        let page = create.page;
        let list = create.list;
        let pages = create.pages;
        list.items = pages[page.current - 1] || [];
        this.SetModalSelection();
      },
      ClearCreateListOrders() {
        let create = this.modal.create;
        create.search.shipping_description = '';
        create.search.offset = 1;
        create.page = {
          current: 1,
          number: 1,
          last: 1
        };
        create.checked = [];
        create.items = [];
        create.pages = [];
        create.list = {
          items: [],
          entries: 0,
          selected: 0
        };
        create.select.option = {
          select_all: true,
          deselect_all: false,
          select_page: true,
          deselect_page: false
        };
        this.ModalQueryOrders();
      },
      ClickOpen(e) {
        if (!e) return;
        let row = {};
        let elm = e.target;
        if (elm.localName != 'tr') row = elm.closest('tr');
        const filter = ['actions', 'icon dots'];
        const mousedown = (state) => {
          if (state === true || state === false) {
            row.mousedown = state;
          } else {
            return row.mousedown;
          }
        }
        for (let i = 0; i < this.clickables.length; i++) {
          if (this.clickables[i] != row) {
            this.clickables[i].classList.remove('hover', 'selected');
          }
        }
        if (elm.localName == 'a') return;
        if (filter.some(x => new RegExp(x).test(elm.className))) return;
        if (e.type == 'mousedown') {
          mousedown(e.which == 1);
        }
        if (e.type == 'mousemove') {
          //row.classList.remove('hover');
          mousedown(false);
        }
        if (e.type == 'mouseup') {
          if (mousedown()) {
            //row.classList.add('hover');
            this.ViewListShow(row.dataset.id);
          }
          mousedown(false);
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .grid-info {
    flex-wrap: nowrap;

    .grid-heading {
      flex-shrink: 0;
    }

    &.flex-center {
      align-items: center;
      margin-bottom: 85px;

      .grid-actions {
        width: 100%;
        margin: 0 0 0 calc(10% + 20px);

        .label {
          width: 100%;

          &.courier {
            max-width: 300px;
          }
        }

        .button {
          flex-shrink: 0;
        }

        > * + * {
          margin-left: 20px;
        }
      }
    }
  }

  .v-select {
    img, .flag-icon {
      float: left;
      max-height: 22px;
      margin-right: 10px;
      position: relative;

      &.courier {
        border-radius: 50%;
      }
    }

    .count {
      height: 22px;
      padding: 4px;
      display: inline-flex;
      min-width: 22px;
      text-align: center;
      font-weight: bold;
      color: #606060;
      background: #f0f0f1;
      border-radius: 12px;
      font-size: 0.75em;
      position: relative;
      align-items: center;
      justify-content: center;
      align-self: center;
      margin-left: 10px;
      margin-left: auto;
      float: right;

      &.spinner {
        color: transparent;

        &::after {
          $b: 2px;
          top: $b;
          left: $b;
          right: $b;
          bottom: $b;
          display: block;
          content: '';
          position: absolute;
          border-radius: 50%;
          border: $b solid transparent;
          border-top: $b solid #606060;
          animation: spin 0.8s linear infinite;
        }
      }
    }
  }

  .order-id {
    max-width: 18%;
  }
  .company {
    max-width: 16%;
  }
  .webshop {
    max-width: 18%;
  }
  .order-date {
    max-width: 20%;
  }

  .bulk-id {
    max-width: 60px;
  }
  .orders {
    max-width: 70px;
  }
  .created-at {
    max-width: 150px;
  }
  .created-by {
    max-width: 100px;
  }
  .reference {
    max-width: 150px;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .label.timezone {
    width: 340px;
    flex-shrink: 0;
    align-items: center;
    flex-direction: row;
    margin-right: 60px;

    .label-text {
      flex-shrink: 0;
      margin-right: 10px;
    }

    .v-select {
      width: 100%;
    }
  }
</style>